import { Avatar, Card, List, Button, Checkbox, Form, Input, Layout, Row, Col, Select, DatePicker, Descriptions, Upload } from 'antd';
import type { DatePickerProps } from 'antd';
import { LockOutlined, UserOutlined, MailOutlined, MobileOutlined, InboxOutlined, AimOutlined, IdcardOutlined, UsergroupAddOutlined, BranchesOutlined, EditOutlined, RightOutlined } from '@ant-design/icons';
import React, { useState } from 'react';
import * as request from "superagent";
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';
import moment from 'moment';
import CryptoJS from 'crypto-js';
import { redirect  } from "react-router-dom";



const Login: React.FC = () => {
    const [form] = Form.useForm();
    const { Option } = Select;
    const { Meta } = Card;

    const data = [
      {
        title: 'My Order',
        icon: 'UserOutlined',
      },
      {
        title: 'My Subscriptions',
        icon: 'UserOutlined',
      },
      {
        title: 'Promos',
        icon: 'UserOutlined',
      },
      {
        title: 'Payment Methotds',
        icon: 'UserOutlined',
      },
      {
        title: 'Help & My Tickets',
        icon: 'UserOutlined',
      },
      {
        title: 'Invite Friends',
        icon: 'UserOutlined',
      },
    ];

    const [loginst, setLogin] = useState(false);

    const onFinish = (values: any) => {
        console.log('Success:', values);
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    /* const onChangePhone = (value: any) => {
        console.log(value);
    } */
    
    const onRegister = async (values: any) => {
        console.log('Success:', values);

        
        //hash password
        const mkpass = values.password + 'altiaMgmPassword';
        const password = CryptoJS.SHA256(mkpass).toString();
        let date = values.birthDate;

        var a = moment(JSON.stringify(date), moment.defaultFormat).toDate()
        var dateB = moment(a).format('YYYY-MM-DD');

        let data = JSON.stringify({
            "fullName":values.fullName,
            "address":values.address,
            "city":values.city,
            "province":values.province,
            "postCode":values.postCode,
            "country":values.country,
            "email":values.email,
            "mobilePhone":values.mobilePhone.replaceAll('%2B', '+'),
            "birthDate":dateB,
            "birthCity":values.birthCity,
            "nik":values.nik,
            "uplineReferralCode":values.uplineReferralCode,
            "hashedPassword":password
        });

        const datapkey = data + 'altiaMGM-aqa!@1-w11234uimnwe';
        
        const dt = CryptoJS.SHA256(datapkey).toString();

        
        //change data
        data = data.replaceAll('+', '%2B');

        console.log({datapkey:datapkey, data:dt, sendData: data})

        // callback
        const url = 'https://altiamgm.hopto.org/apiV101/registerNewMember?data=' + data + '&key=' + dt;
        request
        .post( url )
        .end((err, result) => {
        // Calling the end function will send the request
        console.log({result:result});
        });

    };

    
    const onLogin = async (values: any) => {
        console.log('Success login:', values);
        
        //hash password
        const username = values.username.replaceAll('%2B', '+');
        //const username = values.username;
        const mkpass = values.password + 'altiaMgmPassword';
        const password = CryptoJS.SHA256(mkpass).toString();

        //data
        let data = JSON.stringify({"loginId":username,"hashedPassword":password});

        const salt = 'altiaMGM-aqa!@1-w11234uimnwe';

        const datapkey = data + salt;
        
        const dt = CryptoJS.SHA256(datapkey).toString();


        //change data
        data = data.replaceAll('+', '%2B');

        // callback
        const url = 'https://altiamgm.hopto.org/apiV101/login?data=' + data + '&key=' + dt;
        request
        .post( url )
        .end((err, result) => {
        // Calling the end function will send the request
        let results = result.body;
        if(results.result == 100){
            setLogin(true);
            loginDetail();
        }else{
            setLogin(false);
        }
        
        console.log({datapkey:datapkey, data:dt, from:'login', username: username, values: values});
        console.log({result:result});
        });

    };

    const loginDetail = async () => {
        console.log('getMember Info');

        return redirect("/promo");
        
        /* const data = JSON.stringify({"MemberId":10027,"loginToken":"7Zy008k9WnLjBzR4"});

        const salt = 'altiaMGM-aqa!@1-w11234uimnwe';

        const datapkey = data + salt;
        
        const dt = CryptoJS.SHA256(datapkey).toString();

        console.log({datapkey:datapkey, data:dt, from:'loginDetail'});

        // callback
        const url = 'https://altiamgm.hopto.org/apiV101/queryMemberDetail?data=' + data + '&key=' + dt;
        request
        .post( url )
        .end((err, result) => {
        // Calling the end function will send the request
        console.log({memberdetail:result});
        }); */

    };
    
    const onCancel = () => {
        form.resetFields();
        console.log('Cancel:');
        setRegister(!register);
    };

    const [register, setRegister] = useState(false);
    const Register = () => {
        form.resetFields();
        setRegister(!register);
    };

    const dateFormat = "YYYY-MM-DD";

    
    const normFile = (e: any) => {
        console.log('Upload event:', e);
        if (Array.isArray(e)) {
        return e;
        }
        return e?.fileList;
    };

    if (!register && loginst) {
        return (
            <Layout style={{background: "#ffffff"}}>
                <Row className='profile' style={{width: "100%", padding: "5px 12px"}}>
                    <Col xs={{ order: 1 }} sm={{ order: 1 }} md={{ order: 1 }} lg={{ order: 1 }} style={{width:"100%"}}>
                        <List.Item
                            actions={[<Button type="default" shape="circle" icon={<EditOutlined />} />]}
                        >
                            <List.Item.Meta
                            avatar={<Avatar src="https://joeschmoe.io/api/v1/random" />}
                            title="Andri"
                            description={
                            <Col>
                            <span>+628155186668</span><br></br>
                            <span>wdamailist@gmail.com</span>
                            </Col>}
                            />
                        </List.Item>
                    </Col>
                </Row>
                <Row style={{width: "100%", padding: "5px 12px"}}>
                    <Col xs={{ order: 1 }} sm={{ order: 1 }} md={{ order: 1 }} lg={{ order: 1 }} style={{width:"100%"}}>
                    <Descriptions title="Account"></Descriptions>
                        <List
                            itemLayout="horizontal"
                            dataSource={data}
                            renderItem={item => (
                            <List.Item
                                actions={[<Button type="default" shape="circle" className="btnList" icon={<RightOutlined />} />]}
                            >
                                <List.Item.Meta
                                avatar={<Button type="default" shape="circle" icon={<item.icon />} />}
                                title={<a href="./ralt/kategori">{item.title}</a>}
                                />
                            </List.Item>
                            )}
                        />
                    </Col>
                </Row>
            </Layout>
        );
    }else if(!register && !loginst){
        return (
            <Layout>
                <Row justify="space-evenly">
                    <Col xs={{ order: 1 }} sm={{ order: 2 }} md={{ order: 3 }} lg={{ order: 4 }}>
                        <Form style={{ marginTop: "25vh" }}
                            form={form}
                            name="normal_login"
                            className="login-form"
                            initialValues={{
                                remember: true,
                            }}
                            onFinish={onLogin}
                        >
                            <Form.Item
                                name="username"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Isi Email atau No.Hp!',
                                    },
                                ]}
                            >
                                <Input autoComplete="off" prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Email atau No.Hp" />
                            </Form.Item>
                            <Form.Item
                                name="password"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Isi Password!',
                                    },
                                ]}
                            >
                                <Input
                                    prefix={<LockOutlined className="site-form-item-icon" />}
                                    type="password"
                                    placeholder="Password"
                                />
                            </Form.Item>
                            <Form.Item>
                                <Form.Item name="remember" valuePropName="checked" noStyle>
                                    <Checkbox>Selalu Login</Checkbox>
                                </Form.Item>

                                <a className="login-form-forgot" href="">
                                    Forgot password
                                </a>
                            </Form.Item>
                            <Form.Item>
                                <Button type="primary" htmlType="submit" className="login-form-button" shape="round">
                                    Masuk
                                </Button>
                                Atau <a onClick={Register}>Daftar!</a>
                            </Form.Item>
                        </Form>
                    </Col>
                </Row>
            </Layout>
        );
    } else {
        return (
            <Layout>
                <Row justify="space-evenly">
                    <Col xs={{ order: 1 }} sm={{ order: 2 }} md={{ order: 3 }} lg={{ order: 4 }}>
                        <Form style={{ paddingTop: "15vh", maxWidth: "75%", margin: "0 auto"}}
                            form={form}
                            name="register"
                            className="register-form"
                            onFinish={onRegister}
                        >
                            <Descriptions>
                                <Descriptions.Item>
                                    Masukkan kode teman yang mengundang Anda bergabung dengan member Altia.
                                </Descriptions.Item>
                            </Descriptions>
                            <Form.Item
                                name="uplineReferralCode"
                                rules={[
                                    {
                                        required: false,
                                        message: 'Referral Code',
                                    },
                                ]}
                            >
                                <Input autoComplete="off" prefix={<UsergroupAddOutlined className="site-form-item-icon" />} placeholder="Referral Code" />
                            </Form.Item>
                            <Descriptions>
                                <Descriptions.Item>
                                Identitas Anda akan digunakan untuk akumulasi dan penghitungan bonus poin, data akan dirahasiakan.
                                </Descriptions.Item>
                            </Descriptions>
                            <Form.Item
                                name="fullName"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Isi Nama',
                                    },
                                ]}
                            >
                                <Input autoComplete="off" prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Nama" />
                            </Form.Item>
                            <Form.Item
                                name="email"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Isi Email',
                                    },
                                ]}
                            >
                                <Input autoComplete="off" prefix={<MailOutlined className="site-form-item-icon" />} placeholder="Alamat Email" />
                            </Form.Item>
                            <Form.Item
                                name="mobilePhone"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Isi No.Hp',
                                    },
                                ]}
                            >
                                
                                <Input autoComplete="off" prefix={<MobileOutlined className="site-form-item-icon" />} placeholder="No.HP" />
                            </Form.Item>
                            <Form.Item
                                name="nik"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Isi No.KTP',
                                    },
                                ]}
                            >
                                <Input autoComplete="off" prefix={<IdcardOutlined className="site-form-item-icon" />} placeholder="No.KTP" />
                            </Form.Item>
                            {/* <Form.Item
                                name="birthDate"
                                //initialValue={tgllahir}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Isi Tgl.Lahir!',
                                    },
                                ]}
                            >
                                <DatePicker format={dateFormat} className='tgllahir' style={{width: "100%"}} />
                            </Form.Item>
                            <Form.Item
                                name="birthCity"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Isi Kota Lahir',
                                    },
                                ]}
                            >
                                <Input autoComplete="off" prefix={<AimOutlined className="site-form-item-icon" />} placeholder="Kota Kelahiran" />
                            </Form.Item>
                            <Form.Item
                                name="address"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Isi Alamat',
                                    },
                                ]}
                            >
                                <Input autoComplete="off" prefix={<AimOutlined className="site-form-item-icon" />} placeholder="Alamat" />
                            </Form.Item>
                            <Form.Item
                                name="city"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Isi Kota',
                                    },
                                ]}
                            >
                                <Input autoComplete="off" prefix={<AimOutlined className="site-form-item-icon" />} placeholder="Kota" />
                            </Form.Item>
                            <Form.Item
                                name="province"
                                label="Provinsi"
                                required tooltip="Harus di isi."
                                rules={[
                                    {
                                        required: true,
                                        message: 'Isi Provinsi',
                                    },
                                ]}
                            >
                            <Select style={{ width: "100%" }}>
                                <Option value="Aceh">Aceh</Option>
                                <Option value="Sumatra Utara">Sumatra Utara</Option>
                                <Option value="Sumatra Selatan">Sumatra Selatan</Option>
                                <Option value="Sumatra Barat">Sumatra Barat</Option>
                                <Option value="Bengkulu">Bengkulu</Option>
                                <Option value="Riau">Riau</Option>
                                <Option value="Kepulauan Riau">Kepulauan Riau</Option>
                                <Option value="Jambi">Jambi</Option>
                                <Option value="Lampung">Lampung</Option>
                                <Option value="Bangka Belitung">Bangka Belitung</Option>
                                <Option value="Kalimantan Timur">Kalimantan Timur</Option>
                                <Option value="Kalimantan Barat">Kalimantan Barat</Option>
                                <Option value="Kalimantan Tengah">Kalimantan Tengah</Option>
                                <Option value="Kalimantan Selatan">Kalimantan Selatan</Option>
                                <Option value="Kalimantan Utara">Kalimantan Utara</Option>
                                <Option value="DKI Jakarta">DKI Jakarta</Option>
                                <Option value="Banten">Banten</Option>
                                <Option value="Jawa Barat">Jawa Barat</Option>
                                <Option value="Jawa Tengah">Jawa Tengah</Option>
                                <Option value="DIY">DIY</Option>
                                <Option value="Jawa Timur">Jawa Timur</Option>
                                <Option value="Bali">Bali</Option>
                                <Option value="Nusa Tenggara Barat">Nusa Tenggara Barat</Option>
                                <Option value="Nusa Tenggara Timur">Nusa Tenggara Timur</Option>
                                <Option value="Sulawesi Utara">Sulawesi Utara</Option>
                                <Option value="Sulawesi Barat">Sulawesi Barat</Option>
                                <Option value="Sulawesi Tengah">Sulawesi Tengah</Option>
                                <Option value="Gorontalo">Gorontalo</Option>
                                <Option value="Sulawesi Tenggara">Sulawesi Tenggara</Option>
                                <Option value="Sulawesi Selatan">Sulawesi Selatan</Option>
                                <Option value="Maluku Utara">Maluku Utara</Option>
                                <Option value="Maluku">Maluku</Option>
                                <Option value="Papua Barat">Papua Barat</Option>
                                <Option value="Papua">Papua</Option>
                                <Option value="Papua Selatan">Papua Selatan</Option>
                                <Option value="Papua Tengah">Papua Tengah</Option>
                                <Option value="Papua Pegunungan">Papua Pegunungan</Option>
                            </Select>
                            </Form.Item>
                            <Form.Item
                                name="postCode"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Isi Kode Pos',
                                    },
                                ]}
                            >
                                <Input autoComplete="off" prefix={<AimOutlined className="site-form-item-icon" />} placeholder="Kode Pos" />
                            </Form.Item>
                            <Form.Item
                                name="country"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Isi Kode Negara',
                                    },
                                ]}
                            >
                                <Input autoComplete="off" prefix={<AimOutlined className="site-form-item-icon" />} placeholder="Negara" />
                            </Form.Item> */}
                            <Form.Item label="File KTP">
                                <Form.Item name="dragger" valuePropName="fileList" getValueFromEvent={normFile} noStyle>
                                <Upload.Dragger name="files" action="/upload.do">
                                    <p className="ant-upload-drag-icon">
                                    <InboxOutlined />
                                    </p>
                                    <p className="ant-upload-text">Click or drag file to this area to upload</p>
                                    <p className="ant-upload-hint">Support for a single or bulk upload.</p>
                                </Upload.Dragger>
                                </Form.Item>
                            </Form.Item>
                            <Form.Item
                                name="password"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Isi Password!',
                                    },
                                ]}
                            >
                                <Input
                                    prefix={<LockOutlined className="site-form-item-icon" />}
                                    type="password"
                                    placeholder="Password"
                                />
                            </Form.Item>

                            <Form.Item>
                                <Button type="primary" htmlType="submit" className="login-form-button" shape="round">
                                    Register
                                </Button>
                                Sudah ada Akun? <a onClick={onCancel}> LOGIN DI SINI</a>
                            </Form.Item>
                        </Form>
                    </Col>
                </Row>
            </Layout>
        );
    }

};

export default Login;