import { Carousel, Image, Col, Anchor, Descriptions } from 'antd';
import React from 'react';

import Topup from '../component/topup';
import Kategori from '../component/kategori';
import RefProgress from '../component/progress';
import Promotion from '../component/promotion';

const contentStyle: React.CSSProperties = {
    height: '160px',
    color: '#fff',
    lineHeight: '160px',
    textAlign: 'center',
    background: '#364d79',
};

const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
const code = urlParams.get('code')

const { Link } = Anchor;
const handleClick = (
    e: React.MouseEvent<HTMLElement>,
        link: {
            title: React.ReactNode;
            href: string;
        },
    ) => {
        e.preventDefault();
        console.log(link);
};

const goto = (url: any) => (
        console.log({test:url})
);

console.log({code:code});
const Home: React.FC = () => (
    <Col>

    <Topup />

    <Kategori />

    <RefProgress />

    <Col style={{padding: "0 15px"}}>
    <Descriptions title="Belanja Makin Hemat">
      <Descriptions.Item>
        Dapetin diskon dengan harga spesial merchan sebelum kehabisan!
      </Descriptions.Item>
    </Descriptions>
    </Col>

    <Promotion />
    </Col>
);

export default Home;