import { Card, Progress, Row, Col, Image, Avatar, Button } from 'antd';
import { CheckCircleOutlined, PlusSquareOutlined,ShopOutlined } from '@ant-design/icons';
import React from 'react';


const { Meta } = Card;
const Topup: React.FC = () => (
    
    <Row className="topUp">
    <Col flex="100px">
      <Card style={{ marginTop: 0, borderRadius: "7px" }}>
        <Meta
          avatar={<Avatar src="https://joeschmoe.io/api/v1/random" />}
          title="Altia Saldo"
          description="250.000"
        />
      </Card>
    </Col>
    <Col flex="auto" style={{display:"inline-grid", alignItems:"center"}}>
      <Row justify="center">
        <Col span={6} xs={{ order: 1 }} sm={{ order: 2 }} md={{ order: 3 }} lg={{ order: 4 }}>
          <Button style={{ background: "transparent", color: "#ffffff" }} type="text" icon={<CheckCircleOutlined />} >Pay</Button>
        </Col>
        <Col span={6} xs={{ order: 1 }} sm={{ order: 2 }} md={{ order: 3 }} lg={{ order: 4 }}>
          <Button style={{ background: "transparent", color: "#ffffff" }} type="text" icon={<PlusSquareOutlined />} >Top Up</Button>
        </Col>
        {/* <Col span={4}>
          <Button style={{ background: "transparent", color: "#ffffff" }} type="text" icon={<ShopOutlined />} >Explore</Button>
        </Col> */}
      </Row>
    </Col>
  </Row>
);

export default Topup;