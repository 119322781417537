import { Carousel, Image, Col, Anchor, Descriptions } from 'antd';
import React from 'react';
import Topup from '../component/topup';
import Kategori from '../component/kategori';
import RefProgress from '../component/progress';
import Promotion from '../component/promotion';

const contentStyle: React.CSSProperties = {
    height: '160px',
    color: '#fff',
    lineHeight: '160px',
    textAlign: 'center',
    background: '#364d79',
};

const { Link } = Anchor;
const handleClick = (
    e: React.MouseEvent<HTMLElement>,
        link: {
            title: React.ReactNode;
            href: string;
        },
    ) => {
        e.preventDefault();
        console.log(link);
};

const goto = (url: any) => (
        console.log({test:url})
);

const Promo: React.FC = () => (
    <Col>

    <RefProgress />

    <Col style={{padding: "0 15px"}}>
    <Descriptions title="Promo 30% Diskon">
      <Descriptions.Item>
        Belanja Makin Hemat Dapetin diskon dengan harga spesial merchan sebelum kehabisan!
      </Descriptions.Item>
    </Descriptions>
    </Col>

    <Promotion />
    </Col>
);

export default Promo;