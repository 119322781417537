import { Carousel, Image, Col, Anchor } from 'antd';
import React from 'react';
import images from '../assets/icon';

const contentStyle: React.CSSProperties = {
    height: '160px',
    color: '#fff',
    lineHeight: '160px',
    textAlign: 'center',
    background: '#364d79',
};

const { Link } = Anchor;
const handleClick = (
    e: React.MouseEvent<HTMLElement>,
        link: {
            title: React.ReactNode;
            href: string;
        },
    ) => {
        e.preventDefault();
        console.log(link);
};

const goto = (url: any) => (
        console.log({test:url})
);

const Promotion: React.FC = () => (
    <Col>
        <Carousel autoplay>
            <div>
                <Image
                    style={{ borderRadius: 21 }}
                    width="100%"
                    src={images.myimage}
                    preview={false}
                    onClick={(url) => goto(url)}
                />
            </div>
            <div>
                <Image
                    style={{ borderRadius: 21 }}
                    width="100%"
                    src={images.myimage2}
                />
            </div>
        </Carousel>
    </Col>
);

export default Promotion;