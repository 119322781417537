import { Avatar, Card, List, Button, Checkbox, Form, Input, Layout, Row, Col, Select, DatePicker, Descriptions } from 'antd';
import type { DatePickerProps } from 'antd';
import { ShopOutlined, TagOutlined, TagsOutlined, MobileOutlined, AimOutlined, IdcardOutlined, BranchesOutlined, EditOutlined, RightOutlined } from '@ant-design/icons';
import React, { useState } from 'react';
import * as request from "superagent";
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';
import moment from 'moment';
import CryptoJS from 'crypto-js';

const RegisterMerchan: React.FC = () => {
  const [form] = Form.useForm();
  const { Option } = Select;
  
    
  const onRegister = async (values: any) => {


      let data = JSON.stringify({
          "memberId": 10027,
          "loginToken": "7Zy008k9WnLjBzR4",
          "name":values.name,
          "description":values.description,
          "address":values.address,
          "postCode":values.postCode,
          "city":values.city,
          "province":values.province,
          "country":values.country,
          "mobilePhone":values.mobilePhone.replaceAll('%2B', '+'),
          "latitude": "-7.007792239850837",
          "longitude": "110.39566420629419"
      });

      const datapkey = data + 'altiaMGM-aqa!@1-w11234uimnwe';
      
      const dt = CryptoJS.SHA256(datapkey).toString();

      
      //change data
      data = data.replaceAll('+', '%2B');

      console.log({datapkey:datapkey, data:dt, sendData: data})

      // callback
      const url = 'https://altiamgm.hopto.org/apiV101/registerNewMerchant?data=' + data + '&key=' + dt;
      request
      .post( url )
      .end((err, result) => {
      // Calling the end function will send the request
      console.log({result:result});
      });

  };

  const dateFormat = "YYYY-MM-DD";

  return(
    <Layout style={{background: "#ffffff", marginTop: "10vh"}}>
    <ShopOutlined className="site-form-item-icon" style={{fontSize: "36px"}} />
    <h4 style={{textAlign: "center", fontWeight:"bold"}}>Register Merchan</h4>
      <Row justify="space-evenly">
        
          <Col xs={{ order: 1 }} sm={{ order: 2 }} md={{ order: 3 }} lg={{ order: 4 }} className="registerMerchan">
              <Form style={{ marginTop: "2vh" }}
                  form={form}
                  name="register"
                  className="register-form"
                  onFinish={onRegister}
              >
                  <Form.Item
                      name="name"
                      rules={[
                          {
                              required: true,
                              message: 'Isi Nama',
                          },
                      ]}
                  >
                      <Input autoComplete="off" prefix={<TagsOutlined className="site-form-item-icon" />} placeholder="Nama Merchan" />
                  </Form.Item>
                  <Form.Item
                      name="description"
                      rules={[
                          {
                              required: true,
                              message: 'Isi Diskripsi',
                          },
                      ]}
                  >
                      <Input autoComplete="off" prefix={<TagOutlined className="site-form-item-icon" />} placeholder="Diskripsi" />
                  </Form.Item>
                  <Form.Item
                      name="mobilePhone"
                      rules={[
                          {
                              required: true,
                              message: 'Isi No.Hp',
                          },
                      ]}
                  >
                      
                      <Input autoComplete="off" prefix={<MobileOutlined className="site-form-item-icon" />} placeholder="No.HP" />
                  </Form.Item>
                  <Form.Item
                      name="address"
                      rules={[
                          {
                              required: true,
                              message: 'Isi Alamat',
                          },
                      ]}
                  >
                      <Input autoComplete="off" prefix={<AimOutlined className="site-form-item-icon" />} placeholder="Alamat" />
                  </Form.Item>
                <Form.Item
                    name="postCode"
                    rules={[
                        {
                            required: true,
                            message: 'Isi Kode Pos',
                        },
                    ]}
                >
                    <Input autoComplete="off" prefix={<AimOutlined className="site-form-item-icon" />} placeholder="Kode Pos" />
                </Form.Item>
                  <Form.Item
                      name="city"
                      rules={[
                          {
                              required: true,
                              message: 'Isi Kota',
                          },
                      ]}
                  >
                      <Input autoComplete="off" prefix={<AimOutlined className="site-form-item-icon" />} placeholder="Kota" />
                  </Form.Item>
                  <Form.Item
                      name="province"
                      label="Provinsi"
                      required tooltip="Harus di isi."
                      rules={[
                          {
                              required: true,
                              message: 'Isi Provinsi',
                          },
                      ]}
                  >
                      <Select style={{ width: "100%" }}>
                        <Option value="Aceh">Aceh</Option>
                        <Option value="Sumatra Utara">Sumatra Utara</Option>
                        <Option value="Sumatra Selatan">Sumatra Selatan</Option>
                        <Option value="Sumatra Barat">Sumatra Barat</Option>
                        <Option value="Bengkulu">Bengkulu</Option>
                        <Option value="Riau">Riau</Option>
                        <Option value="Kepulauan Riau">Kepulauan Riau</Option>
                        <Option value="Jambi">Jambi</Option>
                        <Option value="Lampung">Lampung</Option>
                        <Option value="Bangka Belitung">Bangka Belitung</Option>
                        <Option value="Kalimantan Timur">Kalimantan Timur</Option>
                        <Option value="Kalimantan Barat">Kalimantan Barat</Option>
                        <Option value="Kalimantan Tengah">Kalimantan Tengah</Option>
                        <Option value="Kalimantan Selatan">Kalimantan Selatan</Option>
                        <Option value="Kalimantan Utara">Kalimantan Utara</Option>
                        <Option value="DKI Jakarta">DKI Jakarta</Option>
                        <Option value="Banten">Banten</Option>
                        <Option value="Jawa Barat">Jawa Barat</Option>
                        <Option value="Jawa Tengah">Jawa Tengah</Option>
                        <Option value="DIY">DIY</Option>
                        <Option value="Jawa Timur">Jawa Timur</Option>
                        <Option value="Bali">Bali</Option>
                        <Option value="Nusa Tenggara Barat">Nusa Tenggara Barat</Option>
                        <Option value="Nusa Tenggara Timur">Nusa Tenggara Timur</Option>
                        <Option value="Sulawesi Utara">Sulawesi Utara</Option>
                        <Option value="Sulawesi Barat">Sulawesi Barat</Option>
                        <Option value="Sulawesi Tengah">Sulawesi Tengah</Option>
                        <Option value="Gorontalo">Gorontalo</Option>
                        <Option value="Sulawesi Tenggara">Sulawesi Tenggara</Option>
                        <Option value="Sulawesi Selatan">Sulawesi Selatan</Option>
                        <Option value="Maluku Utara">Maluku Utara</Option>
                        <Option value="Maluku">Maluku</Option>
                        <Option value="Papua Barat">Papua Barat</Option>
                        <Option value="Papua">Papua</Option>
                        <Option value="Papua Selatan">Papua Selatan</Option>
                        <Option value="Papua Tengah">Papua Tengah</Option>
                        <Option value="Papua Pegunungan">Papua Pegunungan</Option>
                      </Select>
                  </Form.Item>
                  <Form.Item
                      name="country"
                      rules={[
                          {
                              required: true,
                              message: 'Isi Kode Negara',
                          },
                      ]}
                  >
                      <Input autoComplete="off" prefix={<AimOutlined className="site-form-item-icon" />} placeholder="Negara" />
                  </Form.Item>

                  <Form.Item>
                      <Button type="primary" htmlType="submit" className="login-form-button" shape="round">
                          Daftar Merchan
                      </Button>
                  </Form.Item>
              </Form>
          </Col>
      </Row>
  </Layout>
  );
}

export default RegisterMerchan;