import myimage from './1.png';
import myimage2 from './2.png';
import achievement from './icon/achievement.png';
import icon_topup from './icon/icon_topup.png';
import icon_scan from './icon/icon-scan.png';
import menu_chat from './icon/menu-chat.png';
import menu_favourite from './icon/menu-favourite.png';
import menu_home from './icon/menu-home.png';
import menu_promo from './icon/menu-promo.png';
import agen_properti from './icon/kategori/agen-properti.png';
import aksesoris_mobil from './icon/kategori/aksesoris-mobil.png';
import alat_tulis from './icon/kategori/alat-tulis.png';
import alat_musik from './icon/kategori/alat-musik.png';
import avatar_profile from './icon/kategori/avatar-profile.png';
import barbershop from './icon/kategori/barbershop.png';
import bengkel_mobil from './icon/kategori/bengkel-mobil.png';
import binatu from './icon/kategori/binatu.png';
import buah from './icon/kategori/buah.png';
import burger from './icon/kategori/burger.png';
import bunga from './icon/kategori/bunga.png';
import cake from './icon/kategori/cake.png';
import coffee_shop from './icon/kategori/coffee-shop.png';
import dokter_gigi from './icon/kategori/dokter-gigi.png';
import dokter from './icon/kategori/dokter.png';
import elektronik from './icon/kategori/elektronik.png';
import es_krim from './icon/kategori/es-krim.png';
import fashion_pria from './icon/kategori/fashion-pria.png';
import fashion_wanita from './icon/kategori/fashion-wanita.png';
import gadget from './icon/kategori/gadget.png';
import groceries from './icon/kategori/groceries.png';
import gym from './icon/kategori/gym.png';
import healthy_beauty from './icon/kategori/healthy-beauty.png';
import hobi from './icon/kategori/hobi.png';
import jam from './icon/kategori/jam.png';
import jasa_lain from './icon/kategori/jasa-lain.png';
import kids_baby from './icon/kategori/kids_baby.png';
import klinik from './icon/kategori/klinik.png';
import kolam_renang from './icon/kategori/kolam-renang.png';
import komputer from './icon/kategori/komputer.png';
import mainan from './icon/kategori/mainan.png';
import makanan from './icon/kategori/makanan.png';
import material from './icon/kategori/material.png';
import minimarket from './icon/kategori/minimarket.png';
import minuman from './icon/kategori/minuman.png';
import musik from './icon/kategori/musik.png';
import optik from './icon/kategori/optik.png';
import parfum from './icon/kategori/parfum.png';
import penginapan from './icon/kategori/penginapan.png';
import peralatan_masak from './icon/kategori/peralatan-masak.png';
import perhiasan from './icon/kategori/perhiasan.png';
import perlengkapan_bayi from './icon/kategori/perlengkapan-bayi.png';
import pertamini from './icon/kategori/pertamini.png';
import petshop from './icon/kategori/petshop.png';
import roti from './icon/kategori/roti.png';
import salon from './icon/kategori/salon.png';
import senam from './icon/kategori/senam.png';
import sepatu from './icon/kategori/sepatu.png';
import sepeda from './icon/kategori/sepeda.png';
import studio_foto from './icon/kategori/studio-foto.png';
import tas from './icon/kategori/tas.png';
import travel_agent from './icon/kategori/travel-agent.png';
import warung_makan from './icon/kategori/warung-makan.png';
import wisata from './icon/kategori/wisata.png';

const images = {
  myimage,
  myimage2,
  achievement,
  icon_topup,
  icon_scan,
  menu_chat,
  menu_favourite,
  menu_home,
  menu_promo,
  agen_properti,
  aksesoris_mobil,
  alat_tulis,
  alat_musik,
  avatar_profile,
  barbershop,
  bengkel_mobil,
  binatu,
  buah,
  burger,
  bunga,
  cake,
  coffee_shop,
  dokter_gigi,
  dokter,
  elektronik,
  es_krim,
  fashion_pria,
  fashion_wanita,
  gadget,
  groceries,
  gym,
  healthy_beauty,
  hobi,
  jam,
  jasa_lain,
  kids_baby,
  klinik,
  kolam_renang,
  komputer,
  mainan,
  makanan,
  material,
  minimarket,
  minuman,
  musik,
  optik,
  parfum,
  penginapan,
  peralatan_masak,
  perhiasan,
  perlengkapan_bayi,
  pertamini,
  petshop,
  roti,
  salon,
  senam,
  sepatu,
  sepeda,
  studio_foto,
  tas,
  travel_agent,
  warung_makan,
  wisata,
}
export default images;